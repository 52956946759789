<template>
  <div class="index_box" @touchmove.prevent="">
    <transition :name="positive ? 'route-move' : 'move-route'">
      <router-view class="child_views" />
    </transition>
    <van-tabbar
      v-fitIphoneX="{ type: 'padding', pxNum: -15 }"
      v-model="active"
      @change="tab_jump"
      :fixed="true"
      active-color="#ee0a24"
      z-index="999"
    >
      <van-tabbar-item
        v-for="(item, index) in tablist"
        :name="item.id"
        :icon="active === item.id ? item.active : item.icon"
        :key="index"
      >
        {{ item.title }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import native from "@u/native.js";
import getTokenMixin from "@/mixin/getToken.js";
import { HANDLE_CONTACT, TOKENKEY } from "@/mixin/mixin_file.js";
import LocalCache from "@/utils/cache.js";

export default {
  components: {},
  mixins: [getTokenMixin],
  data() {
    return {
      active: 1,
      positive: true,
    };
  },
  computed: {
    ...mapState({
      sellId: (state) => state.seller.id,
    }),
    tablist() {
      return [
        {
          id: 1,
          path: "/home",
          title: "首页",
          type: "local",
          icon: require("@/assets/img/shop/home.png"),
          active: require("@/assets/img/shop/home_a.png"),
        },
        {
          id: 2,
          path: "/goods",
          title: "商品",
          type: "local",
          icon: require("@/assets/img/shop/goods.png"),
          active: require("@/assets/img/shop/goods_a.png"),
        },
        {
          id: 3,
          path: "/category",
          title: "分类",
          type: "local",
          icon: require("@/assets/img/shop/cat.png"),
          active: require("@/assets/img/shop/cat_a.png"),
        },
        {
          id: 4,
          url: "http://www.baidu.com",
          title: "联系客服",
          type: "remote",
          icon: require("@/assets/img/shop/service.png"),
          active: require("@/assets/img/shop/service.png"),
        },
      ];
    },
    id() {
      if (this.$route.query.shopId) {
        return this.$route.query.shopId;
      }
      return undefined;
    },
  },
  watch: {
    $route(newroute, oldroute) {
      this.positive = newroute.meta.order > oldroute.meta.order;
      let item = this.tablist.find((item) => item.path === newroute.path);
      if (item) {
        this.active = item.id;
      }
    },
  },
  methods: {
    ...mapActions({
      loadSeller: "seller/loadSeller",
    }),
    tab_jump(id) {
      const item = this.tablist[id - 1];
      if (item.type && item.type !== "local") {
        native(HANDLE_CONTACT, null);
      } else {
        this.$router.replace({
          path: item.path,
          query: {
            shopId: this.id,
          },
        });
      }
    },
  },
  created() {
    this.$store.commit("seller/setId", this.id);

    // 初始化
    this.tablist.forEach((item) => {
      if (this.$route.path === item.path) {
        this.active = item.id;
      }
    });
    // // 获取用户Token
    // const token = this.getToken();
    // console.log("token",token);
    // LocalCache.deleteCache(TOKENKEY);
    // if (token) {
    //   LocalCache.setCache(TOKENKEY, token);
    // }

    this.loadSeller(this.id);
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.index_box {
  width: 100%;
  height: 100%;
}

.route-move-enter {
  //入场前
  transform: translate3d(100%, 0, 0);
}
.move-route-enter {
  //入场前
  transform: translate3d(-100%, 0, 0);
}
.route-move-leave-to {
  //离场后
  transform: translate3d(-100%, 0, 0);
}
.move-route-leave-to {
  //离场后
  transform: translate3d(100%, 0, 0);
}
.route-move-enter-active, .route-move-leave-active,.move-route-enter-active, .move-route-leave-active     //激活状态
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transition: transform 0.3s;
}
.child_views {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
}
::v-deep .van-hairline--top-bottom {
  position: fixed;
}
@supports (bottom: env(safe-area-inset-bottom)) {
  body,
  .footer {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>
